import React from 'react';
import ComingSoon from '../../components/coming-soon';
import Layout from "../../components/layout"
// import { Helmet } from 'react-helmet'
import SEO from "../../components/seo"
import ProTabs from '../../components/pro-tabs'
import {tabsContent} from "../../lib/pro-tabs-content"

const WhiteLabel = () => {
  const comingSoonDetails = {
    backgroundImage: require('../../images/pro-banner3@2x.jpg'),
    heading: "We won't be offended if you don't want them to know",
    subheading: "White label removes all mentions of Billsby from your checkout, account management, invoices and just about everywhere else. So if you don't want your customers to know we exist (but you still want to use our handy pre-built tools and user interface), we won't make a fuss. Or charge the earth."
  }

  return(
    <Layout>
      <SEO 
        title="White Label | Billsby Pro | Take your subscription business to the next level" 
        description="White Label and other Billsby Pro features let you take your subscription business to the next level." 
        url="https://www.billsby.com/pro/whitelabel"
      />

      <ComingSoon 
        backgroundImage={comingSoonDetails.backgroundImage}
        heading={comingSoonDetails.heading}
        subheading={comingSoonDetails.subheading} />
      <ProTabs content={tabsContent} />
    </Layout>
  )
}

export default WhiteLabel;